/* eslint-disable max-len */
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import React from "react";

const btt_light = cx("flex-none text-gray-900 bg-white border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-gray-600 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-800");
const btt_green = cx("text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800");
const btt_dark = cx("text-white bg-gray-800 hover:bg-gray-900 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-800 dark:border-gray-700");


const CallUs = props => <div className="flex flex-wrap place-items-center justify-center m-4 gap-2">
    <a href="tel:+919497454647" type="button" className={ btt_light }>  <FontAwesomeIcon size="lg" icon={ faPhone } className="mx-2" style={ { width:"16px" } }/> <span className="hidden md:inline-block	 lg:inline-block">+919497454647</span></a>
    <a href="https://wa.me/919497454647" className={ btt_green }>  <FontAwesomeIcon size="lg" icon={ faWhatsapp } className="mx-2" style={ { width:"16px" } } /> <span className="hidden md:inline-block	 lg:inline-block">+919497454647</span></a>
    <a href="https://wa.me/919497474645" className={ btt_green }>  <FontAwesomeIcon size="lg" icon={ faWhatsapp } className="mx-2" style={ { width:"16px" } } /> <span className="hidden md:inline-block	 lg:inline-block">+919497474645</span></a>
    <a href="mailto:tascaretvm@gmail.com" className={ btt_dark }> <FontAwesomeIcon size="lg" icon={ faEnvelope } className="mx-2"  style={ { width:"16px" } }/> <span className="hidden md:inline-block	 lg:inline-block">tascaretvm@gmail.com</span></a>

</div>;

export default CallUs;
