
//http://react-responsive-carousel.js.org/#clean-carousel

import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
    StylableComponentInterface,
    TestableComponentInterface
} from "../models";

interface ISectionProps
    extends TestableComponentInterface,
    StylableComponentInterface {
    slides: any;
    showThumbs: boolean;
}


export const CarouselView = (props: ISectionProps): ReactElement => {

    const { t } = useTranslation();
    const data = useStaticQuery(graphql`
    query heros {
      allMdx(
        filter: {frontmatter: {model: {eq: "hero"}}}
        sort: {fields: frontmatter___order, order: ASC}
      ) {
        nodes {
          frontmatter {
            title
            model
            order
            heroimage {
              file {
                childImageSharp {
                  gatsbyImageData(formats: [WEBP, JPG])
                  fluid(fit: FILL) {
                    srcWebp
                    srcSetWebp
                    originalName
                  }
                }
              }
            }
          }
        }
      }
    }    
  `);
    const heros = data.allMdx.nodes;
    // console.log("herosherosherosheros");
    // console.log(heros);

    return (
        <Carousel
            showThumbs={ (props.showThumbs ? props.showThumbs : false) }
            showArrows={ true }
            showStatus={ true }
            infiniteLoop={ true }
            autoPlay={ true }
            stopOnHover={ true }
        >
            { heros.map((hero: any,i:number) => (
                <GatsbyImage key={ i }
                    image={ getImage(hero.frontmatter.heroimage.file) }
                    alt={ hero.frontmatter.title }
                />
                // <div key={ i }>
                //    <img src={ slide.src } className="lazyload" title={ slide.title } alt={ slide.title } /> 
                // </div>
            )) }
        </Carousel>
    );
};

