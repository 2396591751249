/* eslint-disable max-len */
import { faChartLine, faFlag, faGraduationCap, faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StaticImage } from "gatsby-plugin-image";
import React, { ReactElement } from "react";
import CallUs from "./callus";
// import avatarImg from "../assets/images/logo-big.png";


const HBlock = props => <div data-aos="fade-up" className="bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
    <div className="flex flex-col items-center pb-2">
        <FontAwesomeIcon size="3x" icon={ props.icon } className="m-2 group-hover:text-amber-400" style={ { width:"48px" } }/>
        <h5 className="mb-2 text-2xl tracking-tight text-amber-300 group-hover:text-orange-700  text-center">{ props.title }</h5>
        <p className="font-normal text-gray-700 dark:text-gray-400 group-hover:text-white text-center">{ props.desc }</p>
    </div>
</div>;


export const WhyUsView = (props:any): ReactElement => {
    const { serviceinfo } = props;

    // const { t } = useTranslation();

    return (
        <section style={ { color: "white" } } className="mb-6">
            <div className="grid grid-flow-row flex-wrap sm:grid-cols-1 auto-rows-max place-items-center">
                <div className="flex flex-row grow flex-wrap place-items-center justify-center">
                    <div className="flex-none h-16">
                        <StaticImage src="../assets/images/logo-1.png"
                            alt="Travancore auto spa logo"
                            height={ 72 }
                        />
                    </div>
                    <div className="grow content-end m-4  justify-center  place-items-center text-center">
                        <h1 className="hidden text-4xl md:text-7xl lg:text-7xl md:flex flex-row w-full place-content-center">
                            <span className="flex-col mr-2 grid grid-cols-1">
                                <span className="flex-row">
                                    <span className="goldtxt">T</span>.
                                    <span className="goldtxt ml-1 mr-1">A</span>.
                                    <span className="goldtxt ml-1">S</span>
                                </span>
                                <span className="grid-row text-base md:text-sm text-gray-400 italic">(<span className="goldtxt">T</span>ravancore <span className="goldtxt">A</span>uto <span className="goldtxt">S</span>pa)</span>
                            </span>
                            <span className="flex-col">Detailing Studio</span>
                        </h1>
                        { /* <!-- visible only on mobile--> */ }
                        <h1 className="md:hidden text-4xl md:text-7xl lg:text-7xl flex flex-row w-full place-content-center">
                            <span className="goldtxt">T</span>.
                            <span className="goldtxt ml-1 mr-1">A</span>.
                            <span className="goldtxt ml-1">S</span>
                            <span className="ml-1">Detailing Studio</span>
                        </h1>
                        <span className="md:hidden grid-row text-base md:text-sm text-gray-400 italic">(<span className="goldtxt">T</span>ravancore <span className="goldtxt">A</span>uto <span className="goldtxt">S</span>pa)</span>
                    </div>
                    <div className="flex-none place-items-start md:place-items-end lg:place-items-end place-content-end">
                        <span className="inline-block mb-10 md:mt-10 lg:mt-10">Since 2013</span>
                    </div>
                </div>
                <div className="flex flex-row  grow place-items-center justify-center ml-12 mr-12 md:mt-10">
                    <article className="prose text-lg">
                        T.A.S (aka <span className="goldtxt">Travancore Auto Spa</span>), Premium Auto<span className="goldtxt"> Detailing Company</span> in
                        Thiruvananthapuram, Kerala, driven by Passion. ❤ Pioneers in Auto Detailing, providing
                        more than { serviceinfo.totalCount } services. We were the first to introduce many cutting
                        edge detailing services &#38; products in kerala, like nano ceramic/graphene coatings, ppf, Glass Repairing,
                        Leather repair, Car Wraps etc
                    </article>
                </div>
                <div className="flex flex-row grow place-items-center justify-center ml-12 mr-12 mt-10 mb-4">
                    <article className="prose text-lg">
                        <span className="ml-6">❤</span> <b>&nbsp;FIRST</b><span className="goldtxt ml-1">Professional Detailing</span> Studio in Kerala.
                        <span className="ml-6">❤</span> <b>&nbsp;FIRST</b> in Kerala to introduce <span className="goldtxt ml-1">nano ceramic</span>,<span className="goldtxt ml-1">nano graphene</span> coatings &#38; <span className="goldtxt ml-1">premium PPF</span> <span className="text-sm">(Paint Protection Film)</span>.
                        <span className="ml-6">❤</span> <b>&nbsp;FIRST</b> in India to get certified by <span className="goldtxt ml-1">Gtechniq (UK)</span> &#38; <span className="goldtxt ml-1">Gyeon (Korea)</span>. <br/>Any many more...
                    </article>
                </div>
                <CallUs/>
                <div className="grid grid-cols-2  md:grid-cols-4 items-stretch mt-10 pl-4 pr-4 justify-center place-items-center gap-2 w-full">
                    <HBlock title="Certified" icon={ faGraduationCap } desc="  Certified by world&apos;s top detailing companies. Gyeon
            Certified Detailer, GTechniq Accredited Detailer, Menzerna
            Approved, Approved by GTGlass (us), Leather Magic (us)"/>
                    <HBlock title="Top Brands" icon={ faHeart } desc=" We are associated with top brands in car detailing industry
                      like, GTechniq (UK), Carpro (Korea),
                      Menzerna (Germany), Gyeon (Korea), nanolex (Germany)"/>

                    <HBlock title="Pioneers in Auto Detailing" icon={ faFlag } desc=" First professional care detailing center in Kerala.
                      Introduced nano ceramic coating, Graphene coating.
                      Exclusive services like GTechniq Crystal Serum, Glass
                      Repair, Leather repair , Imported
                      Car Wraps, PPF instllation etc."/>

                    <HBlock title="Exclusive Services" icon={ faChartLine } desc=" TAS, First professional detailing studio in City *
                      Introduced Ceramic Coating * Introduced Glass repairing *
                      Introduced many international brands * Internationally
                      certified detailer *"/>
                </div>
            </div>
        </section>
    );
};


