/* eslint-disable max-len */
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";




export const ServiceCard = (props:any): ReactElement => {
    const { className, data } = props;
    const { t } = useTranslation();
    const { frontmatter, fields } = data;
    const { heroimage } = frontmatter;
    
    return (
        <div id={ data.id }  data-aos="zoom-out-right" data-aos-duration="1500" className="flex w-5/12 md:w-52 group bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 hover:border-amber-400">
            <a href={ `/${fields.slug}` } >
                <div className=" overflow-hidden">
                    <GatsbyImage
                        image={ getImage(heroimage.thumb ? heroimage.thumb : heroimage.file) }
                        alt={ heroimage.title } className="rounded-t-lg object-cover w-full group-hover:scale-150 transition-all duration-500 ease-in-out transform"/>
                </div>
                <div className="p-2 flex flex-col items-center">
                    <span>
                        <h5 className="text-center mb-2 text-xl md:text-2xl lg:text-2xl font-light tracking-tight text-gray-900 dark:text-amber-400">{ frontmatter.title }</h5>
                    </span>
                    <p className="mb-3 font-light text-center text-gray-700 dark:text-gray-400">{ frontmatter.description }</p>
                
                </div>
            </a>
        </div>
    );
};


