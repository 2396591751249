/* eslint-disable max-len */
import { faFacebook, faGoogle, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faStar, faMapLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactElement } from "react";
import { SectionHead } from "./sectionhead";
import cx from "classnames";

const LocLink = (props:any) => <a href={ props.link } target="_blank" rel="noreferrer"   className="visited:text-text-orange-300 py-2.5 px-5 mr-2 mb-2 text-l font-light text-orange-300 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-orange-300 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-orange-300 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
    <FontAwesomeIcon size="lg" icon={ props.icon } className={ `mx-2 ${props.iconclass}` } />  { props.title }
</a>;

export const LocateUs = (props:any): ReactElement => {
    // const { className, ["data-testid"]: testId, data } = props;

    // const { t } = useTranslation();


    return (
        <section id="locateus_section"  style={ { color: "white" } }  className={ cx(props.className, "pb-10 pt-6") }>
            <div className="grid grid-flow-row grid-cols-1 place-items-center">
                <SectionHead text=" Meet us @"/>
                <div className="flex grow w-screen flex-wrap place-items-start justify-center pt-4 gap-4">
                    <LocLink link="https://www.facebook.com/TravancoreAutoSpa" title="Facebook" icon={ faFacebook } iconclass="text-blue-600"/>
                    <LocLink link="https://www.youtube.com/channel/UCx9Wc6PYrjXEudb2tW3vxvA?view_as=subscriber" title="Youtube" icon={ faYoutube } iconclass="text-red-600"/>
                    <LocLink link="https://www.instagram.com/tastvm/" title="Instagram" icon={ faInstagram } iconclass="text-deep-orange-600"/>
                    <LocLink link="https://g.page/TASDetailingStudio?share" title="Google Map" icon={ faMapLocationDot } iconclass="text-blue-600"/>
                    <LocLink link="https://g.page/r/CZJPkkXCm7zIEAg/review" title="Post Review" icon={ faStar } iconclass="text-amber-400"/>
                    
                  
                </div>
                { /* <div className="flex grow w-screen flex-wrap">
                    <div className="max-w-sm bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
                        <a href="https://g.page/TASDetailingStudio?share">
                            <StaticImage src="../assets/images/tas-map.png" 
                                alt="Google map TAS Detailing Studio" className="rounded-t-lg" />
                        </a>
                        <div className="p-5">
                           
                            <a href="https://maps.google.com/maps/dir//Travancore+Auto+Spa+(T.A.S+Detailing+Studio)+TC+No+6+Maruthi+Hill+Towers+1263-6,+Kanjirampara+Rd,+Thiruvananthapuram,+Kerala+695030,+India/@8.514543,76.980139,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x3b05ba4554f4a519:0xc8bc9bc245924f92" 
                                className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white rounded-lg  focus:ring-4 focus:ring-blue-300  dark:focus:ring-blue-800">
                                Directions
                            </a>
                            <a href="https://g.page/TASDetailingStudio?share" className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white rounded-lg  focus:ring-4 focus:ring-blue-300  dark:focus:ring-blue-800">
                                Open Map
                            </a>
                        </div>
                    </div>

                    <div className="flex flex-grow  bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
                        <div
                            className="fb-page"
                            data-href="https://www.facebook.com/travancoreautospa"
                            data-tabs="timeline"
                            data-width="400"
                            data-height=""
                            data-small-header="true"
                            data-adapt-container-width="true"
                            data-hide-cover="false"
                            data-show-facepile="true"
                        >
                            <blockquote
                                cite="https://www.facebook.com/travancoreautospa"
                                className="fb-xfbml-parse-ignore"
                            >
                                <a href="https://www.facebook.com/travancoreautospa">
                                    ‏‎T.A.S Detailing Studio‎‏
                                </a>
                            </blockquote>
                        </div>
                    </div>

                    




                    
                </div> */ }
            </div>
        </section>
    );
};

/**
 * <iframe width="100%" 
                        title="Google Map TAS Detailing Studio"
                        height="450" 
                        style={ { border:0 } }
                        loading="lazy" 
                        allowFullScreen 
                        src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJGaX0VEW6BTsRkk-SRcKbvMg&key=AIzaSyDguFIPNwQGUVzjFUaBO8NIw3BiDgJsbe8"></iframe>
             
 */


