/* eslint-disable max-len */
import React from "react";
import { InlineFollowButtons } from "sharethis-reactjs";


export const TasFollowButtons = props =>  <div className="social-share-buttons-here text-orange-300 m-6">
    <InlineFollowButtons
        config={ {
            action: "Follow us now : ", // call to action (STRING)
            action_enable: true,  // show/hide call to action (true, false)
            action_pos: "left", // position of call to action (left, top, right)
            alignment: "center",  // alignment of buttons (left, center, right)
            color: "social",       // set the color of buttons (social, white)
            enabled: true,        // show/hide buttons (true, false)
            networks: [           // which networks to include (see FOLLOW NETWORKS)
                "twitter",
                "facebook",
                "instagram",
                "youtube"
            ],
            padding: 8,           // padding within buttons (INTEGER)
            profiles: {           // social profile links for buttons
                facebook: "TravancoreAutoSpa",
                instagram: "tastvm",
                youtube: "/channel/UCx9Wc6PYrjXEudb2tW3vxvA?view_as=subscriber"
            },
            radius: 9,            // the corner radius on each button (INTEGER)
            size: 32,             // the size of each button (INTEGER)
            spacing: 10            // the spacing between buttons (INTEGER)
        } }
    />
</div>
;
