/* eslint-disable max-len */
import { faFacebook, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactElement } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { SectionHead } from "./sectionhead";
import reviewdata from "../../contents/reviews.json";
import cx from "classnames";


const ReviewBlock = props => <div className=" flex-none w-full h-full p-4 text-gray-500 bg-white rounded-lg shadow bg-inherit dark:text-gray-400 ">
    <div className="p-4">
        <div className="mb-2 w-auto ">
            <p className="m-2 text-center break-words flex-wrap text-gray-400">
                &quot;&nbsp;
                { props.data.review }
                &nbsp;&quot;
            </p>
            <div className="flex flex-col items-center justify-center">
                <div className=" w-12 h-12 overflow-hidden bg-gray-100 border-2 border-indigo-100 rounded-full">
                    <img data-sizes="auto" 
                        data-src={ props.data.photo } alt="img"
                        className="lazyload object-cover object-center w-full h-full" />
                </div>
                <h5 className="font-bold text-indigo-300">{ props.data.name }</h5>
                { props.data.source &&
                    <p className="text-sm text-gray-600">
                        { "GOOGLE" == props.data.source.toUpperCase() && <FontAwesomeIcon size="lg" icon={ faGoogle } className="text-white-300 mr-2"/> }
                        { "FACEBOOK" == props.data.source.toUpperCase() && <FontAwesomeIcon size="lg" icon={ faFacebook } className="text-white-300 mr-2"/> }
                        { props.data.source }</p>
                }
                { props.data.rating &&
                    <p>{ [...Array(props.data.rating)].map((x, i) =>
                        <FontAwesomeIcon key={ i } size="lg" icon={ faStar } className="mx-2 text-amber-400" />
                    ) }
                    </p>
                }
            </div>
        </div>
    </div>
</div>;

export const ReviewsView = (props:any): ReactElement => {

    // const { t } = useTranslation();

    const rvs:Array<any> = [];
    reviewdata.groups.map((grp) => {
        grp.reviews.map((obj) => rvs.push(obj));
    });

    return (
        <section style={ { color: "white" } } className={ cx(props.className, " pb-10 pt-6 place-items-center ml-2 mr-2") }>
            <div className="grid grid-flow-row sm:grid-cols-1 auto-rows-max place-items-center">
                
                <SectionHead text="Hear from our Customers..."/>
                <div className="w-screen flex flex-grow flex-col place-content-center place-items-center justify-center">
                    <Carousel
                        showThumbs={ false }
                        showArrows={ true }
                        showStatus={ false }
                        infiniteLoop={ true }
                        autoPlay={ true }
                        stopOnHover={ true }
                        className=" w-5/6">
                        { rvs.map((obj, jindex) => (
                            <ReviewBlock id={ `review_card${jindex}` }  key={ jindex } data={ obj } />
                        )) }
                    </Carousel>
                </div>
                { /* 
                  <div className="pl-10 pr-10 md:p-4 flex overflow-x-auto gap-6 place-items-end ml-10 mr-10">
                    { rvs.map((obj, jindex) => (
                        <ReviewBlock id={ `review_card${jindex}` }  key={ jindex } data={ obj } />
                    )) }
                </div> */ }
            </div>
        </section>
    );
};
/**
 *  { reviewdata.groups.map((grp, index) => (
                            <div key={ index } className="flex flex-row grow  items-end place-items-center justify-center m-4 gap-4">
                                { grp.reviews.map((obj, jindex) => (
                                    <ReviewBlock key={ jindex } data={ obj } />
                                )) }
                            </div>
                        )) }
 */


