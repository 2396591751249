/* eslint-disable max-len */
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { ReactElement } from "react";
import { SectionHead } from "./sectionhead";


export const PhotosWidget = (props:any): ReactElement => {
    
    const data = useStaticQuery(graphql`
    query albumimages5 {
      allMdx(
        filter: {frontmatter: {model: {eq: "photo"}}}
        sort: {fields: frontmatter___order, order: ASC}
        limit: 8
      ) {
        edges {
          node {
            id
            slug
            fields {
              slug
            }
            frontmatter {
              model
              title
              order
              slug
              file {
                childImageSharp {
                  gatsbyImageData(formats: [WEBP, AVIF, JPG])
                }
              }
            }
          }
        }
      }
    }
  `);
    const { edges } = data.allMdx;

    return (
        <section style={ { color: "white" } } className={ cx(props.className, "pb-10 pt-6 place-items-center") }>
            <div className="grid grid-flow-row sm:grid-cols-1 auto-rows-max place-items-center">
                <SectionHead text="Photos"/>
                <div className="flex flex-row grow  m-2 place-items-start justify-start">
                    <p className="text-left text-l md:text-2xl xl:text-2xl  subpixel-antialiased font-light">
                        Some of the recent photos
                    </p>
                </div>
                
                <div id="photos" className="mt-4 flex flex-wrap items-end justify-center place-items-center gap-4 w-full">
                    
                    <div className="flex grow items-stretch flex-wrap place-items-center justify-center gap-4 ml-2 mr-2">
                        { edges.map(({ node }, index: number) => (
                            <div className="w-1/3 md:w-56 lg:w-56 " key={ index }>
                                <GatsbyImage key={ index } className="imgzoom rounded border border-yellow-500"
                                    image={ getImage(node.frontmatter.file) } title={ node.frontmatter.title } alt={ node.frontmatter.title }/>
                                <p className="text-center text-gray-500 text-sm ml-1 mr-1">{ node.frontmatter.title }</p>

                            </div>
                        )) }
                    </div>
                    <a href="/photos" className="flex flex-row grow w-full place-content-center m-4 md:place-items-end md:place-content-end">
                        <button type="button" className="py-2.5 px-5 mr-2 mb-2 text-l font-medium text-orange-300 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-orange-300 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-orange-300 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                            View more photos
                            <FontAwesomeIcon size="lg" icon={ faArrowRight } className="mx-2" /> </button>

                    </a>
                </div>
            </div>
        </section>
    );
};


