/* eslint-disable max-len */
import React from "react";
import { StickyShareButtons } from "sharethis-reactjs";


export const TasShareButtons = props =>  <div className="social-share-buttons-here">
    <StickyShareButtons
        config={ {
            alignment: "left",  // alignment of buttons (left, center, right)
            color: "social",      // set the color of buttons (social, white)
            enabled: true,        // show/hide buttons (true, false)
            font_size: 16,        // font size for the buttons
            language: "en",       // which language to use (see LANGUAGES)
            networks: [           // which networks to include (see SHARING NETWORKS)
                "whatsapp",
                "linkedin",
                "messenger",
                "facebook",
                "twitter"
            ],
            padding: 12,          // padding within buttons (INTEGER)
            radius: 4,            // the corner radius on each button (INTEGER)
            show_total: true,
            size: 40,
            show_mobile: true,    // show/hide the buttons on mobile (true, false)
            show_toggle: true    
        } }/>
</div>
;
