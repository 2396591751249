/* eslint-disable max-len */
import React, { ReactElement } from "react";
import { SectionHead } from "./sectionhead";
import cx from "classnames";




export const WeBelieve = (props:any): ReactElement => {
    // const { className, ["data-testid"]: testId, data } = props;

    // const { t } = useTranslation();


    return (
        <section style={ { color: "white" } } className={ cx(props.className, "pb-10 pt-6") }>
            <div className="grid grid-flow-row sm:grid-cols-1 auto-rows-max place-items-center">
                <SectionHead text="What we believe in..."/>
                <div className="flex flex-row grow place-items-center justify-center ml-12 mr-12 mt-6  text-xl font-light tracking-wider">
                    <article className="prose text-amber-300">
                        <p><span>&quot;</span> A customer is the most important visitor on our premises. He is not dependent on us. We are dependent on him. He is not an interruption on our work. He is the purpose of it. He is not an outsider on our business. He is part of it. We are not doing him a favour by serving him. He is doing us a favour by giving us an opportunity to do so <span style={ { fontSize: 24 } }>&quot;</span>
                        </p>
                        <p style={ { textAlign:"right", color: "white" } }><span>~ Mahatma Gandhi </span></p>
                    </article>
                </div>
            </div>
        </section>
    );
};


