/* eslint-disable max-len */
import { StaticQuery, graphql } from "gatsby";
import React, { ReactElement } from "react";
import { PPSCardList } from "./ppscardlist";


export const ServicesView = (props:any): ReactElement => {
    return (
        <StaticQuery
            query={ graphql`
            query srvlist {
                allMdx(
                  filter: {frontmatter: {model: {eq: "service"}}}
                  sort: {fields: frontmatter___order, order: ASC}
                ) {
                  edges {
                    node {
                      id
                      slug
                      frontmatter {
                        model
                        title
                        description
                        heroimage {
                          title
                          file {
                            childImageSharp {
                              gatsbyImageData(width: 220)
                            }
                          }
                          thumb {
                            childImageSharp {
                                gatsbyImageData(width: 220)
                            }
                          }
                        }
                      }
                      fields {
                        slug
                      }
                    }
                  }
                }
              }
        ` }
            render={ data => <PPSCardList data={ data } { ...props }  model="service"  className={ props.className }/> }
        />
    );
};
