/* eslint-disable max-len */
import { faFacebook, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const LocLink = (props:any) => <a href={ props.link } target="_blank" rel="noreferrer"   className="visited:text-text-orange-300 py-2.5 px-5 mr-2 mb-2 text-l font-light text-orange-300 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-orange-300 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-orange-300 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
    <FontAwesomeIcon size="lg" icon={ props.icon } className={ `mx-2 ${props.iconclass}` } />  { props.title }
</a>;

const ViewMoreFB = () => <>
    <div className="flex flex-row grow w-full  m-2 place-items-center justify-center mt-10">
        <p className="text-center text-l md:text-2xl xl:text-2xl subpixel-antialiased font-light">
            See more post/photos at our Facebook pages below
        </p>
    </div>
    <div className="flex grow w-full flex-wrap place-items-start justify-center pt-4 gap-4">
        <LocLink link="https://www.facebook.com/TravancoreAutoSpa" title="Travancore Auto Spa" icon={ faFacebook } iconclass="text-blue-600"/>
        <LocLink link="https://www.facebook.com/travancorebikespa" title="Bullet Spa" icon={ faFacebook } iconclass="text-blue-600"/> 
        <LocLink link="https://www.facebook.com/tascustoms" title="Vinyl Wraps" icon={ faFacebook } iconclass="text-blue-600"/>   
        <LocLink link="https://www.youtube.com/channel/UCx9Wc6PYrjXEudb2tW3vxvA?view_as=subscriber" title="Youtube" icon={ faYoutube } iconclass="text-red-600"/>
        <LocLink link="https://www.instagram.com/tastvm/" title="Instagram" icon={ faInstagram } iconclass="text-deep-orange-600"/>
    </div>
</>;

export default ViewMoreFB;
