/* eslint-disable max-len */
import cx from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import React, { ReactElement } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import CallUs from "./callus";
import { SectionHead } from "./sectionhead";


const InputEle = (props:any) => <div className="relative z-0 mb-6 w-full group">
    <input  name={ props.name } id={ props.name }
        { ...props }
        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
    <label htmlFor={ props.name } className="lblstyle">{ props.label }</label>
    { props.required &&
        <p className="hidden mt-2 text-sm text-red-600 dark:text-red-500" id={ `err_${props.id}` }><span className="font-medium"> { props.label } required</span></p>
    }
</div>;

type Inputs = {
    firstname: string,
    lastname: string,
    mobile:string,
    email:string,
    vehicletype:string,
    vehiclecondition:string,
    servicelist1:any,
    servicelist2:any
  };

export const EnqFormView = (props: any): ReactElement => {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = data => console.log(data);

    const graphdata = useStaticQuery(graphql`
    query services {
        allMdx(filter: {frontmatter: {model: {eq: "service"}}}) {
          edges {
            node {
              frontmatter {
                title
              }
            }
          }
        }
      }      
  `);
    const _getSelectValues = (select) =>{
        var result = [];
        var options = select && select.options;
        var opt;
  
        for (var i=0, iLen=options.length; i<iLen; i++) {
            opt = options[i];
  
            if (opt.selected) {
                result.push(opt.value || opt.text);
            }
        }
        return result;
    };

    const _enqFormSubmit = (event)=>{
        // event.preventDefault();
        // handleSubmit(onSubmit);
        const isvalid = true;
        console.log("_enqFormSubmit");
        const form:any = document.getElementById("enqform");
        const fname:any = form["First Name"];
        const lname:any = form["Last Name"];
        const email:any = form["Email"];
        const mobile:any = form["Mobile"];

        const vehicletypes:any = form["vehicletypes"];
        const vehiclecondition:any = form["vehiclecondition"];
        const servicelist1:any = form["servicelist1"];
        const servicelist2:any = form["servicelist2"];
        const extranotes:any = form["extranotes"];
        
        const opt1vals:Array<string> = _getSelectValues(servicelist1);
        const opt2vals:Array<string> = _getSelectValues(servicelist2);

        console.log(fname.value, lname.value, email.value, mobile.value);

        form["Description"].value = `
Vehicle Type : ${ vehicletypes.value }\n
Vehicle Condition : ${ vehiclecondition.value }\n
Services Interested : ${ [...opt1vals, ...opt2vals].join(",") }\n
Extra notes : ${ extranotes.value }\n
        `;

        console.log(form["Description"].value);
        // if(!isvalid){
        //     event.preventDefault();
        // }

        return isvalid;
    };

    const clsopts = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500";
    
    const { edges } = graphdata.allMdx;
    const allsrvstr:Array<string> = edges.reduce((prvval:Array<string>, { node }:any) =>  {prvval.push(node.frontmatter.title); return prvval; }, new Array<string>());
    
    const len = allsrvstr.length/2;
    allsrvstr.sort();

    const arr1:Array<string> = allsrvstr.slice(0, len);
    const arr2:Array<string> = allsrvstr.slice(len);

    const styleformitem = "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline";

    return (
        <section style={ { color: "white" } } className={ cx(props.className, "pb-10 pt-6 place-items-center") }>
            <div className="grid grid-flow-row sm:grid-cols-1 auto-rows-max place-items-center">
                <SectionHead text="Enquiry form" />
                <div className="flex flex-row grow  m-2 place-items-start justify-start">
                    <p className="text-left text-l  subpixel-antialiased font-light m-6">
                        Send us your enquery. Fastest and prefered way to reach us is via Phone or Whatsapp. You may use the below form if you prefer to send us your details and will call you back.
                    </p>
                </div>

                <div id="highlights" className="mt-4 flex flex-wrap items-end justify-center place-items-center gap-4 w-full ">
                    <div className="w-full md:w-3/5 flex-none place-items-center justify-center gap-2 p-4 rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 ">
                        
                        <form id="enqform" action="https://crm.zoho.com/crm/WebToLeadForm" name="WebToLeads2699567000000521003" method="POST"
                            onSubmit={ _enqFormSubmit } acceptCharset="UTF-8">
                            
                            <input type="text" className="hidden" name="xnQsjsdp"
                                value="73d407541fdc4971cd53b7a033347f3810cded5f8d0fde1b7f316ecfb6ee0dfe"></input>
                            <input type="hidden" name="zc_gad" id="zc_gad" value=""></input>
                            <input type="text" className="hidden"  name="xmIwtLD"
                                value="3fc6ea1095a1a8825a92f5a7802036a70a9473d05b772953d2d4868e68449550"></input>
                            <input type="text" className="hidden" name="actionType" value="TGVhZHM="></input>
                            <input type="text" className="hidden" name="returnURL" value="https://stage.tasstudio.in/success"></input>

                            <input type="text" className="hidden" id="ldeskuid" name="ldeskuid"></input>
                            <input type="text" className="hidden" id="LDTuvid" name="LDTuvid"></input>
                            <input type="text" className="hidden"  id="Lead_Status" name="Lead_Status" value="Attempted to Contact"></input>
                            <input type="text" className="hidden"  id="Lead_Source" name="Lead_Source" value="Online Store"></input>
                            <input type="text" className="hidden"   id="Description" name="Description" value="desc"/>

                            <div className="grid xl:grid-cols-2 xl:gap-6">
                                <InputEle label="First Name" type="text" placeholder="" name="First Name" id="firstname" />
                                <InputEle label="Last Name" type="text" id="lastname" name="Last Name"/>
                            </div>
                            <div className="grid xl:grid-cols-2 xl:gap-6">
                                <InputEle label="Mobile Number" type="text" placeholder="" required name="Mobile" id="mobile"/>
                                <InputEle label="Email" type="text"  placeholder="" required name="Email" id="email"/>
                            </div>
                            <div className="grid xl:grid-cols-2 xl:gap-6">
                                <div className="relative z-0 mb-6 w-full group">
                                    <label htmlFor="vehicletypes" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"> Vehicle Type/Category</label>
                                    <select id="vehicletypes" className={ clsopts } name="vehicletypes" onChange={ _enqFormSubmit }>
                                        <option value="hatchback">Hatchback</option>
                                        <option value="sedan">Sedan</option>
                                        <option value="suv">SUV</option>
                                        <option value="bigsuv">Lexury Sedan/Big SUV</option>
                                    </select>
                                </div>
                                <div className="relative z-0 mb-6 w-full group">
                                    <label htmlFor="vehiclecondition" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"> Vehicle Condition</label>
                                    <select id="vehiclecondition" className={ clsopts } name="vehiclecondition" onChange={ _enqFormSubmit }>
                                        <option value="Just Delivered or Expecting Delivery">Just Delivered or Expecting Delivery</option>
                                        <option value="In perfet condition">In perfet condition</option>
                                        <option value="Large swrills, scraches">Large swrills, scraches</option>
                                        <option value="Require a good polishing / Very old">Require a good polishing / Very old</option>
                                    </select>
                                </div>
                            </div>
                            <div className="relative z-0 mb-6 w-full group">
                                <label htmlFor="servicelists" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Select the services interested (multiple)</label>
                                <div className="grid xl:grid-cols-2 xl:gap-6">
                                    <select multiple id="servicelists1" className={ clsopts } name="servicelist1" onChange={ _enqFormSubmit }>
                                        { arr1.map((srvname:string, index:number) => (
                                            <option value={ srvname } key={ index }>{ srvname }</option>
                                        )) }
                                    </select>
                                    <select multiple id="servicelists2" className={ clsopts } name="servicelist2" onChange={ _enqFormSubmit }>
                                        { arr2.map((srvname:string, index:number) => (
                                            <option value={ srvname } key={ index }>{ srvname }</option>
                                        )) }
                                    </select>
                                </div>
                                <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">Select multiple services, use CTRL key</p>

                            </div>
                            <div className="relative z-0 mb-6 w-full group">
                                <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Additional Details</label>
                                <textarea id="message" 
                                    onChange={ _enqFormSubmit }
                                    name="extranotes" rows={ 4 } className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Please mention any additional comments"></textarea>
                            </div>
                            <div  className="flex flex-row flex-grow flex-wrap items-stretch gap-4">
                                <button type="submit" className=" flex-grow bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                                    Send Enquiry
                                </button>
                                <button type="reset"  className="flex-grow bg-red-400 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                                    Clear Form
                                </button>
                            </div>
                        </form>
                    </div>
                    <><div className="flex flex-row  w-full  flex-col-1 grow items-stretch flex-wrap place-items-center justify-center gap-2 p-4">
                        <p className="text-center text-xl md:text-2xl xl:text-2xl subpixel-antialiased font-light">
                            Or contact us now using below channels
                        </p>
                    </div><CallUs />
                    </>
                </div>
            </div>
        </section>
    );
};


