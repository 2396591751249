/* eslint-disable max-len */
import cx from "classnames";
import { StaticImage } from "gatsby-plugin-image";
import React, { ReactElement } from "react";
import { SectionHead } from "./sectionhead";



const txtstyle = cx("text-base text-center align-bottom m-2 font-light tracking-tight text-gray-900 dark:text-white");



export const AwardsView = (props:any): ReactElement => {
    
    return (
        <section style={ { color: "white" } } className={ cx(props.className, "pb-10 pt-6 place-items-center ") }>
            <div className="grid grid-flow-row sm:grid-cols-1 auto-rows-max place-items-center">
                <SectionHead text="Awards &amp; Certifications"/>
                <div id="award1" className="flex flex-wrap items-end justify-center place-items-center gap-4 w-full">
                    <div  data-aos="fade-right" data-aos-duration="1500"  className="w-1/3 md:w-48 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 justify-items-center justify-center">
                        <StaticImage className="imgzoom w-full rounded-t-lg object-contain" src="../assets/images/topgear-2020.jpeg" alt="TAS TopGear Award 2020" />
                        <div className="flex flex-grow">
                            <h5 className={ txtstyle }>TopGear 2020</h5>
                        </div>
                    </div>

                    <div id="award2"   data-aos="fade-right"  data-aos-duration="1500"  className="w-1/3 md:w-48  bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 justify-items-center justify-center">
                        <StaticImage className="imgzoom h-52 w-full  rounded-t-lg object-none object-top " src="https://s3.us-west-2.amazonaws.com/tas-assets/certifications/award-0.jpg" alt="TAS >MSME Award 2019" />
                        <div className="flex flex-grow">
                            <h5 className={ txtstyle }>MSME Award 2019</h5>
                        </div>
                    </div>

                    <div id="award3"   data-aos="zoom-in-up"  data-aos-duration="1500" className="w-1/3 md:w-48  bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 justify-items-center justify-center">
                        <StaticImage className="imgzoom w-full rounded-t-lg object-contain" src="https://s3.us-west-2.amazonaws.com/tas-assets/certifications/gyeon-1-small.jpg" alt="TAS Gyeon Certified April 2015" />
                        <div className="flex flex-grow">
                            <h5 className={ txtstyle }>Gyeon Certified April 2015</h5>
                        </div>
                    </div>

                    <div  id="award4"  data-aos="fade-left"  data-aos-duration="1500" className="w-1/3 md:w-48 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 justify-items-center justify-center">
                        <StaticImage className="imgzoom w-full  rounded-t-lg object-contain" src="https://s3.us-west-2.amazonaws.com/tas-assets/certifications/gtechniq-1-small.jpg" alt="TAS Gtechniq Certified since Nov 2015" />
                        <div className="flex flex-grow">
                            <h5 className={ txtstyle }>Gtechniq Certified since Nov 2015</h5>
                        </div>
                    </div>

                    <div  id="award5"  data-aos="fade-left" data-aos-duration="1500"   className="w-1/3 md:w-48 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 justify-items-center justify-center">
                        <StaticImage className="imgzoom w-full  rounded-t-lg object-contain" src="https://s3.us-west-2.amazonaws.com/tas-assets/certifications/ida-1-small.jpg" alt="TAS IDA Member since 2015" />
                        <div className="flex flex-grow">
                            <h5 className={ txtstyle }>IDA Member since 2015</h5>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};


