/* eslint-disable max-len */
import { faGem } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { ReactElement } from "react";
import { SectionHead } from "./sectionhead";



const txtstyle = cx("text-base text-center align-bottom m-2 font-light tracking-tight text-gray-900 dark:text-white");



export const HighlightsView = (props:any): ReactElement => {
    
    const data = useStaticQuery(graphql`
    query allhighlights {
        allMdx(
          filter: {frontmatter: {model: {eq: "highlight"}}}
          sort: {fields: [frontmatter___year, frontmatter___order], order: ASC}
        ) {
          nodes {
            frontmatter {
              image {
                childImageSharp {
                  gatsbyImageData(formats: [WEBP, AVIF, JPG])
                }
              }
              title
              year
              order
              subtitle
              link
            }
          }
        }
      }
  `);
    const { nodes } = data.allMdx;


    return (
        <section style={ { color: "white" } } className={ cx(props.className, "pb-10 pt-6 place-items-center") }>
            <div className="grid grid-flow-row sm:grid-cols-1 auto-rows-max place-items-center">
                <SectionHead text="Highlights"/>
                <div className="flex flex-row grow  m-2 place-items-start justify-start">
                    <p className="text-left text-l md:text-2xl xl:text-2xl  subpixel-antialiased font-light">
                        Here are some important milestones in our journey
                    </p>
                </div>
                
                <div id="highlights" className="mt-4 flex flex-wrap items-end justify-center place-items-center gap-4 w-full">
                    
                    { nodes.map((node:any, index:number) => (
                        <a key={ index } target="_blank" rel="noreferrer" href={ node.frontmatter.link }  data-aos="fade-right" data-aos-duration="1500"  
                            className=" w-1/3 md:w-56 lg:w-56 flex flex-col rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-600 justify-items-center justify-center">
                            <GatsbyImage
                                image={ getImage(node.frontmatter.image) }
                                alt={ node.frontmatter.title } className="rounded-t-lg object-cover w-full group-hover:scale-150 transition-all duration-500 ease-in-out transform"/>
                            <div className="flex flex-grow justify-center">
                                <h6 className={ txtstyle }> { node.frontmatter.title } </h6>
                            </div>
                            <div className="flex-none w-full justify-center rounded-b-sm">
                                <h4 className="bg-amber-400 justify-center text-center text-2xl text-black"> <FontAwesomeIcon size="sm" icon={ faGem } className="mx-2" /> @ { node.frontmatter.year }</h4>
                            </div>
                            
                           
                        </a>
                    )) }
                   

                </div>
            </div>
        </section>
    );
};


