/* eslint-disable max-len */
import { faFacebook, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactElement } from "react";
import CallUs from "./callus";
import ViewMoreFB from "./morefblink";
import { SectionHead } from "./sectionhead";
// import { useTranslation } from "react-i18next";
import { ServiceCard } from "./servicecard";




/**
 * PPSCardList internal component
 * P - Product
 * P - Projct
 * S - Service
 * @param props 
 * model - service | product
 * @returns 
 */
export const PPSCardList = (props:any): ReactElement => {
    const { className, data , model = "service" } = props;

    // const data = getServices();
    // const { model } = props;
    const { edges } = data.allMdx; 
   
    // const { t } = useTranslation();
    // const allsrvs = srvlist.reduce
    
    
    const count:number =  props.count ? props.count : -1;
    const srvlist:Array<any> = count == -1 ? edges : edges.slice(0, count);
    const showmore:boolean = (model == "project" && count != -1) ? true : srvlist.length != edges.length;

    const morepage:string = model == "project" ? "/projects" : model == "product" ? "/products" : "/services";
    
    const allsrvstr = edges.reduce((prvval:string, { node }:any) =>  prvval += ((prvval.length > 0 ? ", ":"") + node.frontmatter.title),"");

    return (
        <section style={ { color: "white" } } className={ `${props.className} p-1 place-items-center` }>
            <div className="grid grid-flow-row sm:grid-cols-1 auto-rows-max place-items-center">
                
                <SectionHead text={ (model == "product") ? "Products we use" : (model == "project") ? "Recent projects" : "Services we offer" }/>
                <div className="flex flex-row grow  m-2 place-items-start justify-start mt-4 mb-6">
                    <p className="text-center text-xl md:text-2xl xl:text-2xl subpixel-antialiased font-light">
                        <span>
                            { 
                                (model == "product") ? (showmore ? "Our top products" : "List of products we use ") 
                                    : (model == "project") ? (showmore ? `Recent ${ srvlist.length } works completed` : "Works completed") 
                                        : (showmore ? `Here are our top ${ srvlist.length } (out of ${edges.length}) services` : `Here are the full list of services we offer. (${edges.length})`) 
                            } 
                        </span><br/>
                        { (model == "project" && !showmore) &&
                            <span className="text-sm md:text-lg font-light text-gray-400"> Showing a limited subset of our works here, Please visit our <a href="https://www.facebook.com/TravancoreAutoSpa" className="underline">Facebook page</a> to view posts since 2013. </span>
                        }
                    </p>
                </div>
                <div className="flex flex-row flex-wrap items-stretch  justify-center place-items-center gap-2">
                    { srvlist.map((obj:any, index:number) => (
                        <ServiceCard key={ index } data={ obj.node }/>
                    )) }
                </div>
                { showmore &&
                    <div className="flex flex-row grow w-full items-stretch flex-wrap place-items-center justify-center lace-content-center md:place-items-end md:place-content-end p-2">
                        <a href={ morepage } className="flex-none">
                            <button type="button" className="py-2.5 px-5 mr-2 mb-2 text-l font-medium text-orange-300 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-orange-300 focus:z-10 focus:ring-2 focus:ring-amber-700 focus:text-amber-700 dark:bg-gray-800 dark:text-orange-300 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                View more { (model == "product") ? "Products" : (model == "project")  ? "Projects":"Services" }
                                <FontAwesomeIcon size="lg" icon={ faArrowRight } className="mx-2" /> </button>

                        </a>
                        { (model == "service") &&
                            <p className="flex-grow text-xs text-slate-400">
                                <span className="text-sm text-slate-300">View all our { edges.length } services in next page : </span> { allsrvstr }
                            </p>
                        }
                    </div>
                }
                { (model == "project" && !showmore) &&
                    <ViewMoreFB/>
                }
                { !showmore &&
                    <><div className="flex flex-row  w-full  flex-col-1 grow items-stretch flex-wrap place-items-center justify-center gap-2 p-4">
                        <p className="text-center text-xl md:text-2xl xl:text-2xl subpixel-antialiased font-light">
                            Contact us now
                        </p>
                    </div><CallUs />
                    </>
                }       
            </div>
        </section> 
    
    );
};
